import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import Layout from "../components/layout"
import SEO from "../components/SEO"
import Img from "gatsby-image"

/* import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebookSquare,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons"

import { faEnvelopeSquare } from "@fortawesome/free-solid-svg-icons" */

const ContactPage = () => {
  const data = useStaticQuery(graphql`
    query {
      main: file(relativePath: { eq: "images/contact/hero.jpg" }) {
        ...heroImage
      }
      logo: file(relativePath: { eq: "images/logo.png" }) {
        ...logoImage
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Contact - Uytterhaegen Jan BV" pathname="/" />
      <BackgroundImage
        Tag="section"
        className="main-hero"
        fluid={data.main.childImageSharp.fluid}
        backgroundColor={`#040e18`}
      >
        <div className="main-hero-content">
          <div className="main-hero-content-title"></div>
        </div>
      </BackgroundImage>
      {/* <div className="container">
        <div className="wrapper">
          <div className="content">
            <section>
              <div className="two-column">
                <div className="column-60">
                  <h2 className="contact-title">Contacteer ons</h2>
                  <p>
                    Bedankt voor je interesse in ons bedrijf. Aarzel niet om ons
                    vrijblijvend te contacteren met al jouw vragen.
                  </p>
                  <section>
                    <form
                      name="contact"
                      method="POST"
                      data-netlify="true"
                      data-netlify-honeypot="bot-field"
                    >
                      <input type="hidden" name="form-name" value="contact" />
                      <div className="fields">
                        <div className="field half">
                          <input
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Naam"
                          />
                        </div>
                        <div className="field half">
                          <input
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Email"
                          />
                        </div>
                        <div className="field">
                          <textarea
                            name="message"
                            id="message"
                            placeholder="Bericht"
                          ></textarea>
                        </div>
                      </div>
                      <ul className="actions">
                        <li>
                          <input
                            type="submit"
                            className="primary"
                            value="Verstuur"
                          />
                        </li>
                      </ul>
                    </form>
                  </section>
                </div>
                <div className="column-40">
                  <h2 className="contact-title">Volg ons</h2>
                  <section>
                    <ul className="icons">
                      <li>
                        <a
                          href="https://www.facebook.com/bkgconstruct"
                          aria-label="Facebook"
                        >
                          <FontAwesomeIcon
                            className="social-icon facebook"
                            icon={faFacebookSquare}
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.linkedin.com/company/bkg-construct-bvba"
                          aria-label="LinkedIn"
                        >
                          <FontAwesomeIcon
                            className="social-icon linkedin"
                            icon={faLinkedin}
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href="mailto:info@bkgconstruct.be"
                          aria-label="Mailto"
                        >
                          <FontAwesomeIcon
                            className="social-icon mailto"
                            icon={faEnvelopeSquare}
                          />
                        </a>
                      </li>
                    </ul>
                  </section>
                  <h2 className="contact-title">Algemene info</h2>
                  <section>
                    <ul>
                      <li>
                        <a href="tel:+32 (0)498 13 61 23">
                          +32 (0)498 13 61 23
                        </a>
                      </li>
                      <li>BE 0670.472.215</li>
                      <li>Croonestraat 5 – B3300 Tienen</li>
                      <li>Groenstraat 1C – B3470 Kortenaken</li>
                    </ul>
                  </section>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div> */}
      <section>
        <div className="two-column">
          <div className="column-60">
            <h1>Wie we zijn</h1>
            <p>
              Jan Uytterhaegen BV is een jong dynamisch installatie bedrijf
              gevestigd in Tienen- Bost en actief op de markt van airco,
              verwarming en sanitair, begonnen als een eenmanszaak opgericht
              door Jan Uytterhaegen in 1984. Door het succes en de kwalitatieve,
              innoverende service is het bedrijf ondertussen uitgegroeid tot een
              familiale KMO met de huidige zaakvoerders Jo en Bram Uytterhaegen.
            </p>
            <p>
              Wij zorgen voor een
              <strong> totaalpakket, van ontwerp tot montage. </strong>
              Bij ons bent u zeker aan het juiste adres. Zowel klanten die reeds
              volledig weten hoe en wat ze willen, als klanten die nog geen
              uitgebreid idee hebben, kunnen bij ons terecht.
            </p>

            <p>
              Ons bedrijf staat in voor het plaatsen,
              <strong> onderhouden en herstellen</strong> van zowel centrale
              verwarmings- als sanitaire installaties en badkamers. Als
              vooruitstrevend installatie bedrijf dragen wij{" "}
              <strong>kwaliteit</strong> hoog in het vaandel, waardoor wij ook
              enkel gebruik maken van kwaliteitsvolle en innoverende materialen
              en volgen we de laatste nieuwigheden en marktinformatie op de
              voet, en doen we aan continue bijscholing van nieuwe producten.
            </p>
          </div>
          <div className="column-40">
            <Img
              className="inline-logo"
              fluid={data.logo.childImageSharp.fluid}
            />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ContactPage
